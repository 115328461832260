<template>
  <div v-if="loaded">
    <h3 class="flix-html-h3">{{ $t('message.buyCredit') }}</h3>
      <div class="flix-row">
        <div class="flix-col-md-12">
          <div class="flix-form-group">
            <div class="flix-input-group">
              <span class="flix-input-group-addon">{{ $t('message.add', {name: $t('message.credit') }) }}</span>
              <input type="number" min="100" class="flix-form-control" v-model="amount">
            </div>
          </div>
        </div>
      </div>
      <div class="flix-row">
        <div class="flix-col-md-12">
          <div class="flix-form-group">
            <div class="flix-html-h3 flix-html-strong" :key="amount" v-if="amount">
              <span>{{ plans.currency }} {{ getPrice(amount).price.split('.').join(',') }}</span>&nbsp;
              <span class="flix-bg-success flix-html-small" v-if="getPrice(amount).discount">( {{ $t('message.includeDiscount', {discount: getPrice(amount).discount + '%' }) }} ) </span>
            </div>
          </div>
        </div>
      </div>
    <saveBtn v-if="amount" :onClick="function () { setAmount() }">
      <template v-slot:text>
        {{ $t('message.save', { name: amount + ' ' + $t('message.credit') }) }}
      </template>
    </saveBtn>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    callback: Function,
    onChange: Function
  },
  data () {
    return {
      loaded: false,
      amount: 100,
      plans: {
        unit: 0.19,
        tax: 0.19,
        currency: 'EUR',
        locale: 'de_DE',
        prices: [
          { amount: 10 },
          { amount: 50, discount: 0 },
          { amount: 500, discount: 0 }
        ]
      }
    }
  },
  watch: {
    amount (val) { if (!val) { val = 100 } if (val < 100) { val = 100 } this.amount = Math.round(val * 1); this.onChange() }
  },
  methods: {
    setAmount () {
      var data = JSON.parse(JSON.stringify(this.plans))
      this.callback(this.amount, this.getPrice(this.amount), data)
    },
    getPrice (amount) {
      var v = {}
      for (var i = 0; i < this.plans.prices.length; i++) {
        v = this.plans.prices[i]
        if (amount <= this.plans.prices[i].amount) {
          break
        }
      }

      v.price = amount * this.plans.unit

      if (typeof v.discount !== 'undefined') {
        v.price = v.price - (v.price * (v.discount / 100))
      }

      if (typeof v.discount === 'undefined') {
        v.discount = 0
      }
      v.tax = ((v.price * 1) / (1 + (this.plans.tax * 1)))
      v.tax = Math.round(((v.price * 1) - (v.tax * 1)) * 100) / 100

      v.price = Math.round(v.price * 1000) / 1000
      v.price = v.price.toFixed(2)
      return v
    },
    getCoins () {
      var user = this.$getUserVariables()
      this.$store.dispatch('getCredits', {
        user: user.user.md5_id,
        callback: function () {
          this.loaded = true
        }.bind(this)
      })
    }
  },
  mounted () {
    this.getCoins()
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
